import { defineStore } from "pinia";

export const useMembershipDrawerStore = defineStore("useMembershipDrawerStore", () => {
  const isVisible = ref(false);
  const price = ref<number>();
  const discount = ref<number>();

  function open(currentPrice?: number, currentDiscount?: number) {
    isVisible.value = true;

    price.value = currentPrice;
    discount.value = currentDiscount;
  }

  function close() {
    isVisible.value = false;
    // To not have the drawer flickering when closing as the box would get removed
    setTimeout(() => {
      price.value = undefined;
      discount.value = undefined;
    }, 500);
  }

  return { close, discount, isVisible, open, price };
});
